import React from 'react'
import { useState, useEffect } from 'react'
import {Alert} from 'react-bootstrap'
import { ParticipantList } from '../components/ParticipantList'
import { getParticipantList } from '../lib/api'
import type { ParticipantSessions } from '../types/ParticipantSessions'

export const SessionList = () => {
  const [sessionList, setSessionList] = useState([] as ParticipantSessions[])
  const [isLoading, setLoading] = useState(true)
  
  useEffect(() => {
    setLoading(true)
    getParticipantList().then(data => {
       setSessionList(data)
       setLoading(false)
    })
  }, [])
  return (
    <div>
      <h1>Sessions</h1>
      {isLoading && <Alert variant="warning">Loading...</Alert>}
      {!isLoading && <ParticipantList sessions={sessionList} />}
    </div>
  )
}
