import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import { useLocation } from 'wouter'
import { getSessionInfo } from '../lib/api'

import { Session } from '../types/Session'
import { Recording } from '../types/Recording'
import { RecordingC } from './RecordingC'
import { longDate } from '../lib/date_utils'

type SessionInfoProps = {
  session: Session
  recordingId?: string
}

export const SessionInfo = (props: SessionInfoProps) => {
  const [info, setInfo] = useState({ recordings: [] as Recording[] })
  const [err, setErr] = useState<{ message: string } | null>(null);
  const [, setLocation] = useLocation()

  useEffect(() => {
    getSessionInfo(props.session.id)
      .then(data => setInfo(data))
      .catch(err => setErr(err))
  }, [props.session])

  if (err) {
    return <h4 className="error">{err.message}</h4>
  }

  const currentRecording = props.recordingId || (info?.recordings?.length > 0 && info?.recordings[0].id)

  const tabs = _.chain(info.recordings)
    .sortBy('started_at')
    .map(r => {
      return (
        <Tab
          eventKey={r.id}
          key={r.id}
          title={<b>{longDate(new Date(r.started_at))}</b>}
        >
          {r.id === currentRecording && (
            <RecordingC recording={r} session={info} />
          )}
        </Tab>
      )
    })
    .value()
  // return <div>{props.session.id}</div>

  return (
    <Tabs
      id='controlled-tab-example'
      activeKey={currentRecording}
      onSelect={k =>
        setLocation(
          `/sessions/${props.session.participant_id}/${props.session.id}/${k}`
        )
      }
      className='mb-3'
    >
      {tabs}
    </Tabs>
  )
}
