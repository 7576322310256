import React from "react";

import { Alert, Col, Row, Spinner, Button } from "react-bootstrap";

export function TranscriptPlayer({ mediaRef, url, onTimeChange }) {
  const [isWaiting, setIsWaiting] = React.useState(false);
  const [playbackSpeed, setPlaybackSpeed] = React.useState(1);
  const mimeType = "audio/mp4";
  const bufferAlert = (
    <Alert variant="danger">
      <Spinner animation="border" size="sm" variant="danger" /> Buffering,
      please wait for couple of seconds
    </Alert>
  );

  const player = (
    <audio
      controls
      loop={false}
      preload="metadata"
      autoPlay={false}
      controlsList="nodownload"
      ref={mediaRef}
      onWaiting={(e) => setIsWaiting(true)}
      onPlaying={(e) => setIsWaiting(false)}
      onTimeUpdate={(e) => onTimeChange(mediaRef.current.currentTime)}
    >
      <source src={url} type={mimeType} />
      Your browser does not support the audio element.
    </audio>
  );
  
  return (
    <div className="audio-control">
      <Row>
        <Col sm={3}>{player}
        </Col>
        <Col sm={7}>
          <div className="speed-control">
          <Button
              size="sm"
              onClick={() => {
              const permaLink=window.location.href.split('#')[0]+'#'+mediaRef.current?.currentTime
              navigator.clipboard.writeText(permaLink)
              window.location.href=permaLink
              }}
            >&#128279;Copy <br/> permalink
              
            </Button>
            &nbsp;
            <Button
              size="sm"
              onClick={() => {
                mediaRef.current.currentTime -= 10;
              }}
            >
              Skip back <br/> (10 sec)
            </Button>
            &nbsp;
            <Button
              size="sm"
              onClick={() => {
                if (playbackSpeed > 0.1) {
                  const newSpeed = playbackSpeed / 1.2;
                  setPlaybackSpeed(newSpeed);
                  mediaRef.current.playbackRate = newSpeed;
                }
              }}
            >
              Playback <br/> slower
            </Button>
            &nbsp;
              <span>
                <Button
                  size="sm"
                  onClick={() => {
                    setPlaybackSpeed(1.0);
                    mediaRef.current.playbackRate = 1.0;
                  }}
                >
                  Normal speed
                  <br />(currently {playbackSpeed.toFixed(2)}x)
                </Button>
              </span>
            &nbsp;
            <Button
              size="sm"
              onClick={() => {
                if (playbackSpeed < 100) {
                  const newSpeed = playbackSpeed * 1.2;
                  setPlaybackSpeed(newSpeed);
                  mediaRef.current.playbackRate = newSpeed;
                }
              }}
            >
              Playback <br/> faster
            </Button>
            &nbsp;
            <Button
              size="sm"
              onClick={() => {
                mediaRef.current.currentTime += 10;
              }}
            >
              Skip forward <br/> (10 sec)
            </Button>
            
          </div>
        </Col>
        <Col sm={2}>
          <div className="wait-indicator">{isWaiting ? bufferAlert : null}</div>
        </Col>
      </Row>
      
    </div>
  );
}
