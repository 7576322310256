import React from 'react'
import './App.scss'
import { Container, Row, Col, Navbar, Nav } from 'react-bootstrap'
import { Route, Link } from 'wouter'

import { SessionList } from './pages/SessionList'
import { Participant } from './pages/Participant'
import { Healthz } from './pages/Healthz'
import {logoutUser} from './lib/api'

function App ({ auth }: { auth: any }) {
  return (
    <Container>
      <Row>
        <Col>
          <Navbar bg='light' expand='lg'>
            <Container className='d-flex'>
              <Navbar.Brand href='/'>
                <Link href='/'>
                  <a href='/'>
                    <img
                      className='logo'
                      src='/logo.svg'
                      alt='COMPASS Pathways Logo'
                    />
                  </a>
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls='basic-navbar-nav' />
              <Navbar.Collapse id='basic-navbar-nav'>
                <Nav className='justify-content-end' style={{ width: '100%' }}>
                  <Nav.Link href='/'>Home</Nav.Link>
                  <Nav.Link href='https://therapist-companion.compasspathways.com'>
                    Therapist Companion
                  </Nav.Link>
                  <Nav.Link href='https://therapist-companion.compasspathways.com'>
                    Help
                  </Nav.Link>
                  
                  <Nav.Link href='#' onClick={() => logoutUser(auth)}>
                  {auth.user && ( <span>Logout {auth.user.profile.name}</span>)}
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Container>
            <Route path='/' component={SessionList} />
            <Route path='/healthz' component={Healthz} />
            <Route path='/sessions/:participantId'>
              {params =>
                params && (
                  <Participant
                    participantId={params['participantId']}
                    sessionId={undefined}
                    recordingId={undefined}
                  />
                )
              }
            </Route>
            <Route path='/sessions/:participantId/:sessionId'>
              {params =>
                params && (
                  <Participant
                    participantId={params['participantId']}
                    sessionId={params['sessionId']}
                    recordingId={undefined}
                  />
                )
              }
            </Route>
            <Route path='/sessions/:participantId/:sessionId/:recordingId'>
              {params =>
                params && (
                  <Participant
                    participantId={params['participantId']}
                    sessionId={params['sessionId']}
                    recordingId={params['recordingId']}
                  />
                )
              }
            </Route>
          </Container>
        </Col>
      </Row>
    </Container>
  )
}

export default App
