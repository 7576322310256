function pad (num) {
  return ('0' + num).slice(-2)
}

export const hhmmss = sec => {
  let secs = Math.floor(sec)
  var minutes = Math.floor(secs / 60)
  secs = secs % 60
  var hours = Math.floor(minutes / 60)
  minutes = minutes % 60
  return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`
  // return pad(hours)+":"+pad(minutes)+":"+pad(secs); for old browsers
}

const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr)

export const longDate = dt =>
  `${padL(dt.getMonth() + 1)}/${pad(dt.getDate())}/${dt.getFullYear()} ${pad(
    dt.getHours()
  )}:${padL(dt.getMinutes())}`
