import React, { useEffect, useRef, useState } from 'react'
import {Link} from 'wouter'
import { Button } from 'react-bootstrap'
import { TranscriptTab } from './TranscriptTab'
import { getRecordingAudioUrl } from '../lib/api'
import { TranscriptPlayer } from './TranscriptPlayer'
import { hhmmss } from '../lib/date_utils'

export const Transcript = ({ recording, timeOffset, onTimeChange }) => {
  if (!recording.transcript.lines)
    return (
      <div className='error'>
        <h3>Transcript not available.</h3>
        {recording.transcript.failure_reason || "No transcript available for review, please check back later."}
      </div>
    )
  return (
    <TranscriptTab
      transcript={recording.transcript}
      timeOffset={timeOffset}
      onAudioRewind={onTimeChange}
    />
  )
}

export const RecordingC = props => {
  const [timeOffset, setTimeOffset] = useState(0)
  const [audioURL, setAudioURL] = useState('')
  const audioRef = useRef()
  const recording = props.recording
  useEffect(() => {
    getRecordingAudioUrl(recording.id).then(data => {
      setAudioURL(data['url'])
    })
  }, [recording.id])
  const hashChange = () => {
    const hash = window.location.hash
    if (hash) {
      const time = parseFloat(hash.split('#')[1])
      setTimeOffset(time)
      if(audioRef.current){
        console.info("audioRef.current['currentTime'] = ", time)
        // @ts-expect-error
        audioRef.current['currentTime'] = time
      }
    }
  }
  
  useEffect(() => {
    // hashChange()
    window.addEventListener('hashchange', hashChange, false)
    return () => window.removeEventListener('hashchange', hashChange, false)
  }, [])
   useEffect(hashChange, [audioRef.current]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className='recording'>
      <div>
        <h4>Duration: {hhmmss(recording.duration / 1000)}</h4>
        <h5>Location: {props.session.location_name} ({props.session.location_id})</h5>
        <h5>Study: {props.session.study}</h5>
      </div>
      {audioURL && (
        <TranscriptPlayer
          mediaRef={audioRef}
          url={audioURL}
          onTimeChange={setTimeOffset}
        />
      )}
      <Transcript
        recording={recording}
        timeOffset={timeOffset}
        onTimeChange={t => {
          setTimeOffset(t)
          if (audioRef.current)
            // @ts-expect-error
            audioRef.current['currentTime'] = t
        }}
      />
      <Link href='/'>
            <Button href='/'>&lt; &lt; Back to list of sessions</Button>
          </Link>
    </div>
  )
}
