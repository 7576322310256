import React from 'react'
import { ParticipantSessions } from '../types/ParticipantSessions'

import { useState } from 'react'
import DataTable from 'react-data-table-component'
import { useEffect } from 'react'

import { Badge } from 'react-bootstrap'

export type SessionCellProps = {
  session: ParticipantSessions
}

const TherapistCell = ({session}: SessionCellProps) => {
  return <div>{session.therapists.map(t => t.name).join(', ')}</div>
}

const LocationCell = ({session}: SessionCellProps) => {
  return <div>{session.locations.map(t => t.name).join(', ')}</div>
}

const StudyCell = ({session}: SessionCellProps) => {
  return <div>{session.study}</div>
}

const VisitsCell = ({session}: SessionCellProps) => {
  const visits = session.sessions.map(s => (
    <a key={s.id} href={`/sessions/${session.participant_id}/${s.id}`}>
      <Badge>{s.visit}</Badge>
    </a>
  ))
  return <div>{visits}</div>
}

export type ParticipantListProps = {
  sessions: ParticipantSessions[]
}

export const ParticipantList = (props: ParticipantListProps) => {
  const [q, setQ] = useState('')
  const [filteredSessions, setFilteredSessions] = useState(
    [] as ParticipantSessions[]
  )

  useEffect(() => {
    const s = props.sessions.filter(session =>
      session.participant_id.toLowerCase().includes(q.toLowerCase())
    )

    setFilteredSessions(s)
  }, [props.sessions, q])
  const columns = [
    {
      name: 'Participant',
      cell: (row: ParticipantSessions) => (
        <a href={`/sessions/${row.participant_id}/`}>{row.participant_id}</a>
      )
    },
    {
      name: 'Therapists',
      cell: (row: ParticipantSessions) => <TherapistCell session={row} />
    },

    {
      name: 'Location',
      cell: (row: ParticipantSessions) => <LocationCell session={row} />
    },
    {
      name: 'Study',
      cell: (row: ParticipantSessions) => <StudyCell session={row} />
    },
    {
      name: 'Visits',
      cell: (row: ParticipantSessions) => <VisitsCell key={row.participant_id} session={row} />
    }
  ]

  return <div className="ParticipantList">
    <div className="search"><input type="text" value={q} onChange={e => setQ(e.target.value)} /></div>
    <DataTable columns={columns} data={filteredSessions} />
  </div>
}
