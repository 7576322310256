import { convertTherapySession } from './adapters'
import { oidcConfig } from '../auth_config'
import { ParticipantSessions } from '../types/ParticipantSessions'

const API_URL = process.env.REACT_APP_API_URL

const MAX_ITEMS = 1000

const getToken = () => {
  const key = `oidc.user:${oidcConfig.authority}:${oidcConfig.client_id}`
  const auth = JSON.parse(sessionStorage.getItem(key) || '{}')
  // console.log(auth)
  return auth.id_token
}


export const logoutUser = (auth) => {
  const key = `oidc.user:${oidcConfig.authority}:${oidcConfig.client_id}`
  console.log('logoutUser', auth)
  auth.removeUser()
  sessionStorage.removeItem(key)
}
export const getParticipantList = (
  participantId?: string
): Promise<ParticipantSessions[]> => {
  const token = getToken()
  let url = `${API_URL}/api/therapy-session?per_page=${MAX_ITEMS}`
  if (participantId) url = `${url}&participant_id=${participantId}`
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
    .then(res => res.json())
    .then(data => convertTherapySession(data.items))
}

export const getSessionInfo = (sessionId: string) => {
  const token = getToken()
  const url = `${API_URL}/api/therapy-session/${sessionId}?per_page=${MAX_ITEMS}`
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).then(res => {

    if (!res.ok) {
      return res.json().then((body) => {
        throw body
      });
    }

    return res.json();
  })
}

export const getRecordingAudioUrl = (recordingId: string) => {
  const token = getToken()
  const url = `${API_URL}/api/recording/${recordingId}/audio`
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).then(res => res.json())
  // .catch((err) => console.log(err))
}
