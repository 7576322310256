import React from 'react'
import { useState, useEffect } from 'react'
import { getParticipantList } from '../lib/api'
import { ParticipantSessions } from '../types/ParticipantSessions'
import { ParticipantInfo } from '../components/ParticipantInfo'

export const Participant = ({ participantId, sessionId, recordingId }) => {
  const [sessionList, setSessionList] = useState(
    null as ParticipantSessions | null
  )

  useEffect(() => {
    getParticipantList(participantId).then(data => {
      setSessionList(data[0])
    })
  }, [participantId])
  return (
    <div>
      <h1>Participant {participantId}</h1>
      {sessionList && (
        <ParticipantInfo
          sessions={sessionList}
          currentSession={sessionId}
          recordingId={recordingId}
        />
      )}

      <footer>
        Disclaimer: This transcript of the therapy session has been generated
        and redacted using a software application. As such, it is subject the
        limitations of the software’s capabilities and is not guaranteed to be
        100% accurate. If you encounter unredacted personal information in your
        review of the transcripts, please contact{' '}
        <a href='mailto://chanterellesupport@compasspathways.com'>
          chanterellesupport@compasspathways.com
        </a>
        . Please never include patient PII (personally identifiable information)
        in your email, please refer to the patient by participant ID number
        ONLY.
      </footer>
    </div>
  )
}
