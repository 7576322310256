import React, { useEffect, useRef } from 'react'
import { Table } from 'react-bootstrap'

import { Transcript } from '../types/Transcript'

import {hhmmss} from '../lib/date_utils'

type TranscriptTabProps = {
  transcript: Transcript
  timeOffset: number
  onAudioRewind: (ts: number) => void
}

export function TranscriptTab ({ transcript, timeOffset, onAudioRewind }: TranscriptTabProps) {
  const currentRef = useRef(null)
  useEffect(() => {
    if(currentRef.current)
      currentRef.current.scrollIntoView({behavior: 'smooth'})
  }, [currentRef.current, timeOffset]) // eslint-disable-line react-hooks/exhaustive-deps
  const rows = transcript.lines?.map((tr, i) => {
    const isCurrent = tr.start_time <= timeOffset && tr.end_time > timeOffset
    const isCurrentClass = isCurrent ? ' current' : ''
    const content = tr.content.split(/\. /).map((s, i) => <span key={i}>{s}.<br/></span>)

    return (
      <tr key={i} className={`line spk_${tr.speaker_id}${isCurrentClass}`} ref={isCurrentClass?currentRef:null}>
        <th>{i}</th>
        <td className='ts'>
          <a
            href={`#${tr.start_time}`}
            onClick={e => {
             // we still need to set the time manually, in case the hash doesn't change
             onAudioRewind(tr.start_time)
            }}
          >
            {hhmmss(tr.start_time)}
          </a>
        </td>
        <td>{tr.speaker_id}</td>
        <td className='quote'>{content}</td>
      </tr>
    )
  })
  return (
    <div className='transcript-table'>
      <Table bordered size='sm'>
        <thead>
          <tr>
            <th>#</th>
            <th>Time</th>
            <th>Speaker</th>
            <th>Text</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </div>
  )
}
