import React from 'react'
import { useAuth, hasAuthParams } from 'react-oidc-context'
import App from './App'

function AuthApp () {
  const auth = useAuth()

  switch (auth.activeNavigator) {
    case 'signinSilent':
      return <div>Signing you in...</div>
    case 'signoutRedirect':
      return <div>Signing you out...</div>
  }

  if (auth.isLoading) {
    return <div>Loading...</div>
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>
  }

  if (auth.isAuthenticated) {
    console.log("OK, I am authenticated now")
    if(hasAuthParams()) {
      console.log("I have auth params, so I will redirect")
      const redirectUrl = localStorage.getItem('redirect')
      if(redirectUrl !== null) {
        console.log("Redirecting to", redirectUrl)
        localStorage.removeItem('redirect')
        window.location.href = redirectUrl
      }
    }
    return (
     <App auth={auth}/>
    )
  }
  auth.signinRedirect()
  console.log("Redirecting to signin")
  window.localStorage.setItem('redirect', window.location.href)
  return <button onClick={() => void auth.signinRedirect()}>Log in</button>
}

export default AuthApp
