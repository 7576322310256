import React, {useState, useEffect} from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {useLocation} from 'wouter'

import type { ParticipantSessions } from "../types/ParticipantSessions";
import {SessionInfo} from './SessionInfo'


type ParticipantInfoProps = {
    sessions: ParticipantSessions,
    currentSession?: string
    recordingId?: string
}

export const ParticipantInfo = (props: ParticipantInfoProps ) => {
const [session, setSession] = useState(props.sessions.sessions[0])
const [, setLocation] = useLocation()

useEffect(()=>{
    const currentSession = props.sessions.sessions.find((s) => s.id===props.currentSession)
    
    if(currentSession)
        setSession(currentSession)
}, [props.currentSession, props.sessions])
const tabs = props.sessions.sessions.map((s,i) => {
    return <Tab eventKey={s.id} key={s.id} title={s.visit}>
        {s.id===session.id && <SessionInfo session={s} recordingId={props.recordingId}/>}
    </Tab>

})
 // /api/therapy-session/:id
    return (
        <Tabs
          id="controlled-tab-example"
          activeKey={session.id}
          onSelect={(k) => setLocation(`/sessions/${props.sessions.participant_id}/${k}`)}
          className="mb-3"
          
        >
        {tabs}
        </Tabs>
      );
    }