import * as _ from 'lodash';

import type {Session} from '../types/Session';
import type { ParticipantSessions } from '../types/ParticipantSessions';
import { DeviceInfo } from '../types/DeviceInfo';


export const toSession = (item ): Session => {
    const location = {id: item.location_id, name: item.location_name}
    const therapists = item.therapist_ids.map((therapistId, i) => ({ id: therapistId, name: item.therapists[i]}))
    const session = {
        id: item.id,
        visit: item.visit,
        status: item.status,
        started_at: item.started_at,
        ended_at: item.ended_at,
        device: item.device_info as DeviceInfo,
        location,
        therapists,
        participant_id: item.participant_id,
        study: item.study
    }
    
    return session
}

export const convertTherapySession = (therapySessionItems): ParticipantSessions[] => {
    const out = _.chain(therapySessionItems)
        .map(toSession)
        .groupBy('participant_id')
        .toPairs()
        .map(p => {
            const [participantId, sessions] = p;
            return {
                participant_id: participantId,
                sessions: _.sortBy(sessions, 'started_at'),
                study: sessions[0].study,
                therapists: _.chain(sessions).map('therapists').flatten().uniqBy('id').sortBy('name').value(),
                locations: _.chain(sessions).map('location').uniqBy('id').value()
            }
        })
        .sortBy('participant_id')
        .value()
    return out
}